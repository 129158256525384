.add-volunteer {
    h3 {
        font-weight: 600;
        font-size: 16px;
        line-height: 25px;
        color: #000000;
        margin-bottom: 15px;

        span {
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: #000000;
            mix-blend-mode: normal;
            opacity: 0.6;

        }
    }

    .volunteer-form {
        background: #FFFFFF;
        padding: 24px;
    }

    .form-group {
        margin-bottom: 12px;

        label {
            font-weight: 600;
            font-size: 14px;
            line-height: 19px;
            color: #000000;
            opacity: 0.6;
            margin-bottom: 10px;
        }

        .form-control {
            background: #F8F8F8;
            border: 1px solid #CBCBCB;
            border-radius: 2px;
            height: 40px;
            font-size: 12px;
            font-weight: 600;
        }
    }

    .btn-login {
        background-color: #477EFF;
        border-radius: 0;
        color: #EAEAEA;
        font-weight: 700;
        font-size: 14px;
        padding: 10px 60px;
        &:hover {
            color: #EAEAEA;
            background-color: #366dee;
        }
    }
}
.download--report {
    .btn--download {
        padding: 5px 15px !important;
        border-radius: 0px 2px 2px 0px !important;
        z-index: 0 !important;
      }
}

.react-datepicker__input-container {
  input {
    background: #F8F8F8;
    border: 1px solid #CBCBCB;
    border-radius: 2px;
    height: 40px;
    font-size: 12px;
    font-weight: 600;
    padding: 0px 35px 0px 15px;
    width: 100%;
     &:focus {
        outline: 0;
    }
  }
}