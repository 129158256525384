
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
ul,
li,
select,
option,
label,
button,
.select-dropdown,
::placeholder,
input {
  font-family: 'Open Sans', sans-serif !important; 
}
body {
  background: #E5E5E5 !important;
}
.form-control, button {
  &:focus {
    box-shadow: none !important;
  }
}
.error-msg {
  color: red;
    font-size: 14px;
    font-weight: 400;
}
.form-control {
  &::placeholder {
    columns: #000000 !important;

  }
}
.css-yk16xz-control {
  background-color: #F8F8F8 !important;
  border: 1px solid #CBCBCB;
  border-radius: 2px !important;
  height: 40px;
  font-size: 12px;
  font-weight: 600;
}
// .css-1okebmr-indicatorSeparator {
//    display: none;
// }
input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  // cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}
