.registered-holder {
    padding: 15px 24px;
    background-color: #ffffff;
    margin: 15px 0px 18px;

    .register-details {
        border-bottom: 1px solid #eee;
        margin-bottom: 8px;
        padding-bottom: 4px;
        p {
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            color: #000000;
            margin-bottom: 8px;
        }
    }

    .survey-details {
        p {
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: #000000;
            opacity: 0.6;
            margin-bottom: 8px;
        }
    }
    .response-summary {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: #000000;
        margin: 12px 0px 10px;
    }
}