    .modal-backdrop.show {
        background: #000000 !important;
        // opacity: 0.7  !important;
    }

    .modal-body {
        padding: 32px !important;
    }

    .modal-content {
        border: none !important;
        border-radius: 0 !important;
        position: absolute !important;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .btn-login {
        background-color: #477EFF;
        border-radius: 0 !important;
        color: #EAEAEA;
        font-weight: 700;
        font-size: 14px;
        padding: 10px 30px !important;

        &:hover {
            color: #EAEAEA;
            background-color: #366dee;
        }
    }

    .success-details {
        p {
            font-weight: 700;
            font-size: 20px;
            line-height: 27px;
            color: #000000;
            opacity: 0.6;
            margin-bottom: 25px;

            &:nth-child(3) {
                font-weight: 600;
                font-size: 14px;
            }
        }
    }

    .discharge--dates {
        font-weight: 600 !important;
        font-size: 11px !important;
        line-height: 14px !important;
        text-align: center;
            &:nth-last-of-type(1) {
                span {
                display: none;
            }
        }
    }