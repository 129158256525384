.nav-tabs {
    background-color: #ffffff;
}
.nav-tabs .nav-link {
    width: 50%;
    text-align: center;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #000000 !important;
    padding: 15px;
    border: none !important;
    border-radius: 0 !important;
    background: #eee;
}