.login-holder {
    background: #FFFFFF;
    width: 100%;

    .form-group {
        margin-bottom: 24px;

        label {
            font-weight: 600;
            font-size: 14px;
            line-height: 19px;
            color: #000000;
            opacity: 0.6;
            margin-bottom: 10px;
        }

        .form-control {
            background: #F8F8F8;
            border: 1px solid #CBCBCB;
            border-radius: 2px;
            height: 40px;
            font-size: 12px;
            font-weight: 600;
        }
    }

    .btn-login {
        background-color: #477EFF;
        border-radius: 0;
        color: #EAEAEA;
        font-weight: 700;
        font-size: 14px;
        padding: 10px 60px;
        &:hover {
            color: #EAEAEA;
            background-color: #366dee;
        }
    }
    
}

.custom-modal {
    background: #EAEAEA;
    height: 100vh;
    .modal-dialog {
        @media (min-width:576px){
            max-width: 420px !important;
        }
    }
}
.modal-dialog {
    margin: auto !important;
    height: 100%;
}
.modal-backdrop.show {
    background: #EAEAEA ;
}
.modal-body {
    padding: 32px !important;
}

.modal-content {
    border: none !important;
    border-radius: 0 !important;
    position: absolute !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.admin-title {
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    color: #000;
    margin-left:10px;
}
.custom-logo-width {
    width: 60px;
    height: 60px;
}