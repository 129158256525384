.header-holder {
    background: #FFFFFF;
    padding: 16px 0px;

    p {
        margin-bottom: 0;
    }

    .selected-list {
        p {
            font-weight: 700;
            font-size: 20px;
            color: #000000;
            @media (max-width:767px){
                font-size: 17px;
            }
            &:nth-child(2){
                color: #00000045;
                margin-left:5px;
                font-size: 16px;
                @media (max-width:767px){
                    font-size: 15px;
                }
            }
        }
    }
    .btn-logout {
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color: #000000;
        opacity: 0.6;
    }
}